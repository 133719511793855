(function () {
  'use strict';

  class SignUpCtrl {
    constructor($mdDialog, User, $state) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.User = User;
      vm.$state = $state;
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide() {
      let vm = this;
      vm.$mdDialog.hide();
    }
    login(signUpForm) {
      let vm = this;
      if (signUpForm.$valid) {
        vm.User.signup(vm.user)
          .then(()=>{
            vm.$state.go('dashboard.index');
            vm.hide();
          })
          .catch((data)=>{
            if (data.status === 403) {
              vm.error = data;
            }
          });
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('SignUpCtrl', SignUpCtrl);
}());
